import _ from 'lodash'
import { useContext, useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { fetcher } from 'api/fetchers'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import moment from 'moment'
import { openSCLinkAccount } from 'utils'
import { useUser } from './user'
import { theme } from 'styles/theme'
import walmartApi from 'api/walmartApi'
import { CurrentSellerContext } from 'context/CurrentSeller'

export function useAmazon3pSellerData() {
	const queryKey = ['amazon3p/sellers']
	const { isLoading, error, data } = useQuery({
		queryKey: queryKey,
		queryFn: () => fetcher('/sellers'),
	})

	const { user: currentUser } = useUser()
	const queryClient = useQueryClient()

	if (error instanceof Error) {
		if ((error as Error).message !== 'accesstoken is undefined') {
			console.log(error)
			toast.error('Error fetching user data')
		}
	}

	const isEnterprise = useMemo(() => {
		const enterprise = data?.find((seller: any) => !_.isNil(seller.EnterpriseSellerId))
		return !!enterprise
	}, [data])

	async function mutate(updateFunction: any) {
		await queryClient.setQueryData(queryKey, updateFunction)
	}

	async function revalidate() {
		await queryClient.invalidateQueries({
			exact: true,
			queryKey: [queryKey],
			refetchType: 'all',
		})
	}

	useEffect(() => {
		const inactiveSellers = data?.filter((seller: any) => seller?.Active === 0)
		if (!data || !currentUser?.userId) return
		const sellersWithLostAccess = data?.filter((seller: any) => seller?.EnterpriseSeller?.LostScAccess)
		if (sellersWithLostAccess.length > 0) {
			void Swal.fire({
				icon: 'error',
				title: 'Error',
				// For some reason Swal doesn't respect the UL styling to use bullets, so we have to do it ourselves
				// I added the list style of none, in case it's fixed in the future, so that it doesn't have double bullets
				html: `<div>We have lost access to submit audits for the following accounts:</div> <br/> 
				<ul style="list-style-type:none;">
					${sellersWithLostAccess.map((seller: any) => `<li>• ${seller.Name} </li>`)}
				</ul> <br/>
				<div>Please contact <a href='mailto:client_support@valenceintel.com' style="text-decoration:underline;">client_support@valenceintel.com</a> to resume audit submissions.</div>`,
			})
		}

		// Store the sellers as part of the key, so that they get notified again if they have a different set of inactive sellers
		const localStorageKey = 'inactiveSellerAlertDismissed-' + inactiveSellers.map((seller: any) => seller.Id).join(',')
		const localStorageDismissed = localStorage.getItem(localStorageKey)

		if (
			inactiveSellers.length > 0 &&
			(!localStorageDismissed || moment().diff(moment(localStorageDismissed), 'days') > 7) &&
			!data.some((seller: any) => seller.Id === 11)
		) {
			void Swal.fire({
				icon: 'error',
				title: 'Error',
				html: `<div>The following accounts are not active, and we are not pulling data for them:</div> <br/> 
						<ul style="list-style-type:none;">
						${inactiveSellers.map((seller: any) => `<li>• ${seller.Name} </li>`)}
						</ul> <br/>
						<div>To resolve the issue, please relink your Seller Central Account below.</div>
						<br/>
						<div>If this issue persists, Amazon may be preventing us from pulling your data. Please contact <a href='mailto:client_support@valenceintel.com' style="text-decoration:underline;">client_support@valenceintel.com</a> if this reoccurs.</div>`,
				showConfirmButton: true,
				confirmButtonText: 'Relink Account',
				confirmButtonColor: theme.colors.vBlue['600'],
				showCancelButton: true,
				cancelButtonText: 'Snooze',
			}).then((result) => {
				if (result.dismiss === Swal.DismissReason.cancel) {
					localStorage.setItem(localStorageKey, moment().toISOString())
				}
				if (result.isConfirmed && currentUser?.userId) {
					openSCLinkAccount(currentUser.userId)
				}
			})
		}
	}, [currentUser?.userId, data])

	return {
		data,
		error,
		isLoading,
		mutate,
		isEnterprise,
		revalidate,
		queryKey,
	}
}

export function useUpdateCurrentAmazon3pEnterpriseSeller() {
	const { setCurrentAmazon3pSeller } = useContext(CurrentSellerContext)
	const { mutate } = useAmazon3pSellerData()

	async function updateCurrentEnterpriseSeller(update: any) {
		setCurrentAmazon3pSeller((old: any) => {
			return {
				...old,
				EnterpriseSeller: {
					...old.EnterpriseSeller,
					...update,
				},
			}
		})

		await mutate((old: any) => {
			return old.map((seller: any) => {
				if (seller.SellerId === update.SellerId) {
					return {
						...seller,
						EnterpriseSeller: {
							...seller.EnterpriseSeller,
							...update,
						},
					}
				}
				return seller
			})
		})
	}

	return { updateCurrentEnterpriseSeller }
}

export function useUpdateCurrentAmazon3pSellerBusinessDetails() {
	const { setCurrentAmazon3pSeller } = useContext(CurrentSellerContext)
	const { mutate } = useAmazon3pSellerData()

	async function updateCurrentSellerBusinessDetails(update: any) {
		setCurrentAmazon3pSeller((old: any) => {
			return {
				...old,
				SellerBusinessDetail: {
					...old.SellerBusinessDetail,
					...update,
				},
			}
		})

		await mutate((old: any) => {
			return old.map((seller: any) => {
				if (seller.SellerId === update.SellerId) {
					return {
						...seller,
						SellerBusinessDetail: {
							...seller.SellerBusinessDetail,
							...update,
						},
					}
				}
				return seller
			})
		})
	}

	return { updateCurrentSellerBusinessDetails }
}

export function useWalmartSellers() {
	const queryClient = useQueryClient()
	const queryKey = ['/walmart/seller']
	const { isLoading, error, data } = useQuery({
		queryKey: queryKey,
		queryFn: async () => {
			const [url] = queryKey
			const { data } = await walmartApi.get(url)
			return data
		},
	})

	async function revalidate() {
		await queryClient.invalidateQueries({
			exact: true,
			queryKey: [queryKey],
			refetchType: 'all',
		})
	}

	async function mutate(updateFunction: any) {
		await queryClient.setQueryData(queryKey, updateFunction)
	}

	return {
		data,
		isLoading,
		error,
		revalidate,
		mutate,
	}
}

export function useUpdateCurrentWalmart3pEnterpriseSeller() {
	const { setCurrentWalmart3pSeller } = useContext(CurrentSellerContext)
	const { mutate } = useWalmartSellers()

	async function updateCurrentWalmartEnterpriseSeller(update: any, sellerId: any) {
		setCurrentWalmart3pSeller((old: any) => {
			return {
				...old,
				EnterpriseSeller: {
					...old.EnterpriseSeller,
					...update,
				},
			}
		})

		await mutate((old: any) => {
			return old.map((seller: any) => {
				if (seller.Id === sellerId) {
					return {
						...seller,
						EnterpriseSeller: {
							...seller.EnterpriseSeller,
							...update,
						},
					}
				}
				return seller
			})
		})
	}

	return { updateCurrentWalmartEnterpriseSeller }
}
