import React from 'react'
import {
	Box,
	Flex,
	Heading,
	Link,
	ListItem,
	Text,
	UnorderedList,
	VStack,
	useBreakpointValue,
	Drawer,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	IconButton,
	Image,
} from '@chakra-ui/react'
import Logo from 'images/ValenceLogoLight.png'
import { HamburgerIcon } from '@chakra-ui/icons'
import { Helmet } from 'react-helmet'
import Card from 'components/UIComponents/Card'

const PrivacyPolicy = () => {
	const isMobile = useBreakpointValue({ base: true, md: false })
	const sidebarWidth = useBreakpointValue({ base: 'full', md: '280px' })

	const bgColor = 'gray.50'
	const borderColor = 'gray.200'
	const headingColor = 'gray.900'
	const linkColor = 'vBlue.500'

	const [isOpen, setIsOpen] = React.useState(false)
	const toggleSidebar = () => setIsOpen(!isOpen)
	const logoHeight = useBreakpointValue({ base: '70px', md: '73px' })

	const scrollToSection = (id: string) => (e: React.MouseEvent) => {
		e.preventDefault()
		const element = document.getElementById(id)
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' })
		}
		if (isMobile) {
			setIsOpen(false)
		}
	}

	interface SidebarLinkProps {
		href: string
		children: React.ReactNode
	}

	const SidebarLink = ({ href, children }: SidebarLinkProps) => (
		<ListItem>
			<Link
				href={href}
				color={linkColor}
				_hover={{ textDecoration: 'underline' }}
				onClick={scrollToSection(href.replace('#', ''))}
				fontSize='sm'
				display='block'
				py={2}
			>
				{children}
			</Link>
		</ListItem>
	)

	return (
		<Box minH='100vh' bg={bgColor}>
			<Helmet>
				<title>Valence Intelligence | Privacy Policy</title>
			</Helmet>

			{isMobile && (
				<IconButton
					icon={<HamburgerIcon />}
					aria-label='Open Table of Contents'
					position='fixed'
					top={4}
					left={4}
					zIndex={10}
					onClick={toggleSidebar}
					bg='blue.500'
					color='white'
					_hover={{ bg: 'blue.600' }}
				/>
			)}

			<Flex>
				{isMobile ? (
					<Drawer isOpen={isOpen} placement='left' onClose={toggleSidebar}>
						<DrawerOverlay />
						<DrawerContent>
							<DrawerCloseButton />
							<DrawerBody p={6}>
								<Image src={Logo} alt='logo' mb='23px' objectFit='contain' height={logoHeight} />

								<Heading as='h2' size='md' mb={6} color={headingColor}>
									Table of Contents
								</Heading>
								<UnorderedList styleType='none' spacing={3} ml={0}>
									<SidebarLink href='#introduction'>Introduction</SidebarLink>
									<SidebarLink href='#data-categories'>Data Categories & Collection Purposes</SidebarLink>
									<SidebarLink href='#cookies'>Cookies & Data Uses</SidebarLink>
									<SidebarLink href='#data-sharing'>Data Sharing</SidebarLink>
									<SidebarLink href='#security-of-data'>Security Of Data</SidebarLink>
								</UnorderedList>
							</DrawerBody>
						</DrawerContent>
					</Drawer>
				) : (
					<Box
						w={sidebarWidth}
						position='fixed'
						h='100vh'
						borderRight='1px'
						borderColor={borderColor}
						p={8}
						overflowY='auto'
						css={{
							'&::-webkit-scrollbar': { width: '4px' },
							'&::-webkit-scrollbar-track': { width: '6px' },
							'&::-webkit-scrollbar-thumb': { background: 'gray.300', borderRadius: '24px' },
						}}
					>
						<Image src={Logo} alt='logo' mb='23px' objectFit='contain' height={logoHeight} />

						<Heading as='h2' size='md' mb={6} color={headingColor}>
							Table of Contents
						</Heading>
						<UnorderedList styleType='none' spacing={3} ml={0}>
							<SidebarLink href='#introduction'>Introduction</SidebarLink>
							<SidebarLink href='#data-categories'>Data Categories & Collection Purposes</SidebarLink>
							<SidebarLink href='#cookies'>Cookies & Data Uses</SidebarLink>
							<SidebarLink href='#data-sharing'>Data Sharing</SidebarLink>
							<SidebarLink href='#security-of-data'>Security Of Data</SidebarLink>
						</UnorderedList>
					</Box>
				)}

				<Box ml={isMobile ? 0 : '280px'} minH='100vh' w='full' py={12} px={8}>
					<Card p={6}>
						<VStack align='stretch' spacing={8}>
							<Box>
								<Heading as='h1' size='2xl' mb={4} color='vBlue.500'>
									Valence Intelligence Privacy Policy
								</Heading>
								<Text color='gray.500' fontSize='sm'>
									<strong>Last Updated: May 9, 2024</strong>
								</Text>
							</Box>

							<Box id='introduction' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Introduction
								</Heading>
								<Text>
									<Text as='strong' color={headingColor}>
										Valence Intelligence LLC
									</Text>{' '}
									(“us”, “we” or “our”) operates the{' '}
									<Link href='https://valenceintel.com' isExternal color='blue.500' fontWeight='bold'>
										valenceintel.com
									</Link>{' '}
									website (“Service”).
								</Text>
							</Box>

							<Box id='data-categories' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Data Categories & Collection Purposes
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										To enhance our service, we collect various types of information. By visiting our website(s) or using
										our Services, you provide us with the following categories of personal data:
									</Text>

									<Text>
										<Text as='strong' color={headingColor}>
											1. Customer Account and Registration Data:
										</Text>{' '}
										This includes your email address, first and last name, phone number, address, state, province,
										ZIP/postal code, city, cookies, and usage data. It is necessary for creating an account, providing
										support, and maintaining your account.
									</Text>

									<Text>
										<Text as='strong' color={headingColor}>
											2. Service Data (Including Session, Location, and Usage Data):
										</Text>{' '}
										We gather information on how the Service is accessed and used. This includes data automatically
										logged by the website or Service, such as session duration, hardware and device details, IP
										addresses, location, language settings, operating system information, unique device identifiers, and
										other diagnostic data. This information is crucial for delivering, operating, and enhancing our
										Services.
									</Text>
								</VStack>
							</Box>

							<Box id='cookies' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Cookies & Other Website Technologies
								</Heading>
								<Text>
									We utilize cookies and similar tracking technologies to monitor activity on our Service and store
									certain information. You can configure your browser to reject cookies or notify you when a cookie is
									being sent. However, note that disabling cookies may limit your access to certain parts of our
									Service.
								</Text>
							</Box>
							<Box id='how-we-use-data' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									How We Use Your Data
								</Heading>
								<Text mb={4}>
									The collected data by Valence Intelligence LLC serves multiple purposes, which include:
								</Text>
								<UnorderedList spacing={3} pl={5}>
									<ListItem>Providing and ensuring the maintenance of our Service.</ListItem>
									<ListItem>
										Enabling your participation in interactive features of our Service, should you choose to do so.
									</ListItem>
									<ListItem>Offering customer care and support services.</ListItem>
									<ListItem>Providing analysis and valuable insights to enhance the quality of our Service.</ListItem>
									<ListItem>Monitoring the usage patterns of our Service.</ListItem>
									<ListItem>Detecting, preventing, and addressing any technical issues that may arise.</ListItem>
								</UnorderedList>
							</Box>

							<Box id='data-sharing' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Data Sharing
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										Your Personal Data and other information may be transferred to and stored on computers outside your
										jurisdiction, including countries with different data protection laws than those in your state,
										province, or country. If you are outside the United States and choose to provide your Personal
										Information to us, please be aware that we may transfer your data to the United States or other
										countries where our service providers are located. These countries may not offer the same level of
										data protection as your jurisdiction.
									</Text>
									<Text>
										By consenting to this Privacy Policy and submitting your information, you acknowledge and agree to
										such data transfers. Valence Intelligence LLC will take all reasonable steps to ensure that your
										data is treated securely and in accordance with this Privacy Policy. We will not transfer your
										Personal Data to any organization or country without adequate controls in place to safeguard your
										data and personal information.
									</Text>
								</VStack>
							</Box>

							<Box id='data-disclosure' pt={4}>
								<Heading
									as='h3'
									size='md'
									mb={4}
									pb={2}
									borderBottom='1px'
									borderColor={borderColor}
									color={headingColor}
								>
									Data Disclosure
								</Heading>
								<Text mb={4}>
									Under certain circumstances, Valence Intelligence LLC may need to disclose your Personal Data in good
									faith when it is deemed necessary to:
								</Text>
								<UnorderedList spacing={3} pl={5}>
									<ListItem>Comply with any applicable legal obligations.</ListItem>
									<ListItem>Protect and defend the rights or property of Valence Intelligence LLC.</ListItem>
									<ListItem>Investigate or prevent potential wrongdoing associated with the Service.</ListItem>
									<ListItem>Safeguard the personal safety of Service users or the general public.</ListItem>
									<ListItem>Mitigate potential legal liability.</ListItem>
								</UnorderedList>
							</Box>
							<Box id='security-of-data' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Security of Data
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										We place great importance on the security of your data and make diligent efforts to safeguard your
										personal information. However, it is important to note that no method of transmission over the
										internet or electronic storage is entirely secure. Therefore, while we employ commercially
										acceptable measures to protect your personal data, we cannot guarantee its absolute security.
									</Text>
								</VStack>
							</Box>

							<Box id='third-parties' pt={4}>
								<Heading
									as='h3'
									size='md'
									mb={4}
									pb={2}
									borderBottom='1px'
									borderColor={borderColor}
									color={headingColor}
								>
									Third Parties / Service Providers
								</Heading>
								<Text>
									To assist in delivering our services and conducting related tasks on our behalf, such as service
									analysis, we may engage third-party companies and individuals. These entities are only permitted to
									access your personal data for the purpose of performing these tasks and are bound by confidentiality
									obligations, preventing them from disclosing or using the data for any other purposes.
								</Text>
							</Box>

							<Box id='analytics' pt={4}>
								<Heading
									as='h3'
									size='md'
									mb={4}
									pb={2}
									borderBottom='1px'
									borderColor={borderColor}
									color={headingColor}
								>
									Analytics
								</Heading>
								<Text>
									We may utilize third-party service providers, like Google Analytics, to analyze and monitor the usage
									of our service. Google Analytics tracks and reports website traffic and may use the collected data to
									personalize and contextualize ads within its advertising network. You can install the
									<Link href='https://tools.google.com/dlpage/gaoptout' isExternal color='blue.500'>
										{' '}
										Google Analytics opt-out browser add-on{' '}
									</Link>
									if you wish to prevent your activity on our service from being available to Google Analytics. For more
									information about Google’s privacy practices, please refer to the
									<Link href='https://policies.google.com/privacy' isExternal color='blue.500'>
										{' '}
										Google Privacy & Terms
									</Link>{' '}
									page.
								</Text>
							</Box>

							<Box id='third-party-links' pt={4}>
								<Heading
									as='h3'
									size='md'
									mb={4}
									pb={2}
									borderBottom='1px'
									borderColor={borderColor}
									color={headingColor}
								>
									Links to Other Websites
								</Heading>
								<Text>
									Our Service may contain links to third-party websites that are not operated by us. We strongly advise
									you to review every site’s privacy policies. We have no control over and assume no responsibility for
									these third-party sites or services' content, privacy policies, or practices.
								</Text>
							</Box>

							<Box id='childrens-privacy' pt={4}>
								<Heading
									as='h3'
									size='md'
									mb={4}
									pb={2}
									borderBottom='1px'
									borderColor={borderColor}
									color={headingColor}
								>
									Children’s Privacy
								</Heading>
								<Text>
									Our service is not intended for individuals under the age of 18 (“Children”), and we do not knowingly
									collect personally identifiable information from anyone under this age. If you are a parent or
									guardian and become aware that your child has provided us with personal data, please contact us. If we
									discover that we have collected personal data from a child without verifiable parental consent, we
									take steps to remove that information from our servers.
								</Text>
							</Box>

							<Box id='privacy-policy-updates' pt={4}>
								<Heading
									as='h3'
									size='md'
									mb={4}
									pb={2}
									borderBottom='1px'
									borderColor={borderColor}
									color={headingColor}
								>
									Changes to This Privacy Policy
								</Heading>
								<Text>
									We may occasionally update our privacy policy and notify you of any changes by posting the revised
									policy on our website. Additionally, we will send an email and/or display a prominent notice on our
									service before the changes take effect. The “effective date” at the top of this privacy policy will
									also be updated. We recommend reviewing this privacy policy periodically for any updates.
								</Text>
							</Box>

							<Box id='contact-us' pt={4}>
								<Heading
									as='h3'
									size='md'
									mb={4}
									pb={2}
									borderBottom='1px'
									borderColor={borderColor}
									color={headingColor}
								>
									Contact Us
								</Heading>
								<Box bg='gray.100' p={4} borderRadius='md'>
									<Text fontWeight='bold'>
										client_support@valenceintel.com <br />
										Valence Intelligence <br />
										8 The Green, Suite A <br />
										Dover, DE 19701
									</Text>
								</Box>
							</Box>
						</VStack>
					</Card>
				</Box>
			</Flex>
		</Box>
	)
}

export default PrivacyPolicy
