import React from 'react'
import {
	Box,
	Flex,
	Heading,
	Link,
	ListItem,
	Text,
	UnorderedList,
	VStack,
	useBreakpointValue,
	Drawer,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	IconButton,
	Image,
} from '@chakra-ui/react'
import Logo from 'images/ValenceLogoLight.png'

import { HamburgerIcon } from '@chakra-ui/icons'
import { Helmet } from 'react-helmet'
import Card from 'components/UIComponents/Card'

const TermsAndConditions = () => {
	const isMobile = useBreakpointValue({ base: true, md: false })
	const sidebarWidth = useBreakpointValue({ base: 'full', md: '280px' })

	const bgColor = 'gray.50'
	const borderColor = 'gray.200'
	const headingColor = 'gray.900'
	const linkColor = 'vBlue.500'

	const [isOpen, setIsOpen] = React.useState(false)
	const toggleSidebar = () => setIsOpen(!isOpen)
	const logoHeight = useBreakpointValue({ base: '70px', md: '73px' })

	const scrollToSection = (id: string) => (e: React.MouseEvent) => {
		e.preventDefault()
		const element = document.getElementById(id)
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' })
		}
		if (isMobile) {
			setIsOpen(false)
		}
	}

	interface SidebarLinkProps {
		href: string
		children: React.ReactNode
	}

	const SidebarLink = ({ href, children }: SidebarLinkProps) => (
		<ListItem>
			<Link
				href={href}
				color={linkColor}
				_hover={{ textDecoration: 'underline' }}
				onClick={scrollToSection(href.replace('#', ''))}
				fontSize='sm'
				display='block'
				py={2}
			>
				{children}
			</Link>
		</ListItem>
	)

	return (
		<Box minH='100vh' bg={bgColor}>
			<Helmet>
				<title>Valence Intelligence | Terms of Service</title>
			</Helmet>

			{isMobile && (
				<IconButton
					icon={<HamburgerIcon />}
					aria-label='Open Table of Contents'
					position='fixed'
					top={4}
					left={4}
					zIndex={10}
					onClick={toggleSidebar}
					bg='blue.500'
					color='white'
					_hover={{ bg: 'blue.600' }}
				/>
			)}

			<Flex>
				{isMobile ? (
					<Drawer isOpen={isOpen} placement='left' onClose={toggleSidebar}>
						<DrawerOverlay />
						<DrawerContent>
							<DrawerCloseButton />

							<DrawerBody p={6}>
								<Image src={Logo} alt='logo' mb='23px' objectFit='contain' height={logoHeight} />

								<Heading as='h2' size='md' mb={6} color={headingColor}>
									Table of Contents
								</Heading>
								<UnorderedList styleType='none' spacing={3} ml={0}>
									<SidebarLink href='#introduction'>Introduction</SidebarLink>
									<SidebarLink href='#services'>Services</SidebarLink>
									<SidebarLink href='#payment'>Payment</SidebarLink>
									<SidebarLink href='#disclaimers'>Disclaimers & Liability</SidebarLink>
									<SidebarLink href='#security'>Security & Storage</SidebarLink>
									<SidebarLink href='#miscellaneous'>Miscellaneous</SidebarLink>
									<SidebarLink href='#terms'>Additional Terms</SidebarLink>
									<SidebarLink href='#copyright'>Copyright Infringement</SidebarLink>
								</UnorderedList>
							</DrawerBody>
						</DrawerContent>
					</Drawer>
				) : (
					<Box
						w={sidebarWidth}
						position='fixed'
						h='100vh'
						borderRight='1px'
						borderColor={borderColor}
						p={8}
						overflowY='auto'
						css={{
							'&::-webkit-scrollbar': { width: '4px' },
							'&::-webkit-scrollbar-track': { width: '6px' },
							'&::-webkit-scrollbar-thumb': { background: 'gray.300', borderRadius: '24px' },
						}}
					>
						<Image src={Logo} alt='logo' mb='23px' objectFit='contain' height={logoHeight} />

						<Heading as='h2' size='md' mb={6} color={headingColor}>
							Table of Contents
						</Heading>

						<UnorderedList styleType='none' spacing={3} ml={0}>
							<SidebarLink href='#introduction'>Introduction</SidebarLink>
							<SidebarLink href='#services'>Services</SidebarLink>
							<SidebarLink href='#payment'>Payment</SidebarLink>
							<SidebarLink href='#disclaimers'>Disclaimers & Liability</SidebarLink>
							<SidebarLink href='#security'>Security & Storage</SidebarLink>
							<SidebarLink href='#miscellaneous'>Miscellaneous</SidebarLink>
							<SidebarLink href='#terms'>Additional Terms</SidebarLink>
							<SidebarLink href='#copyright'>Copyright Infringement</SidebarLink>
						</UnorderedList>
					</Box>
				)}
				<Box ml={isMobile ? 0 : '280px'} minH='100vh' w='full' py={12} px={8}>
					<Card p={6}>
						<VStack align='stretch' spacing={8}>
							<Box>
								<Heading as='h1' size='2xl' mb={4} color='vBlue.500'>
									Valence Intelligence Terms of Service
								</Heading>
								<Text color='gray.500' fontSize='sm'>
									<strong>Last Updated: May 9, 2024</strong>
								</Text>
							</Box>
							<Box id='introduction' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Introduction
								</Heading>
								<Text>
									Please read these Terms of Service ("Terms") carefully. BY USING THE SERVICE AND THE SITE, YOU AGREE
									TO THESE TERMS. IF YOU DO NOT AGREE, DO NOT USE THE SERVICE OR SITE.
								</Text>
							</Box>

							<Box id='services' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Services
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										<strong>1. Service and Website.</strong> Valence Intelligence (referred to, as applicable, as
										"Valence Intelligence", the "Service", "we" or "us") provides tools and services (the "Service")
										designed to help customers selling on eCommerce marketplaces as well as brick and mortar retailers
										(collectively known as "Retailers.")
									</Text>
									<Text>
										<Text as='strong'>2. Customer.</Text> As a customer of the Service or a representative of an entity
										that is a customer of the Service, you are a "Member" according to this agreement (or "you"). You
										represent that you have full power, capacity and authority to accept these Terms on behalf of
										yourself or your employer.
									</Text>

									<Box>
										<Text mb={4}>
											<Text as='strong'>3. Account Requirements.</Text> To set up an account and use the Service you
											must:
										</Text>
										<UnorderedList spacing={2} pl={5}>
											<ListItem>
												Be 18 years of age, or the age of majority in your province, territory or country
											</ListItem>
											<ListItem>
												Provide your legal full name, valid email address, company name, and any other information we
												request
											</ListItem>
											<ListItem>Provide us with one or more Payment Methods</ListItem>
											<ListItem>Personally and manually create your account without using any automated means</ListItem>
										</UnorderedList>
									</Box>

									<Box>
										<Text mb={4}>
											<Text as='strong'>4. Account Ownership.</Text>
										</Text>
										<Text>
											The member who created the account and whose Payment Method is charged (the "Account Owner") has
											access to and control over the account and is responsible for any activity that occurs through the
											account. To maintain control over the account and prevent anyone from accessing the account, the
											Account Owner should maintain control over access to the Service and not reveal the password or
											details of the Payment Method associated with the account to anyone. Account owners are not
											allowed to share their account, log-in or any other access related credentials with any other
											party unless explicitly stated otherwise in the details related to the specific plan they
											purchased. Absent an explicit multi-user authorization, all accounts are intended for single-use
											only. You are responsible for updating and maintaining the accuracy of the information you provide
											to us relating to your account. We can terminate your account or place your account on hold in
											order to protect you and/or Valence Intelligence. You must immediately notify us of any
											unauthorized use of your account. We will not be liable for any loss or damage from your failure
											to comply with this security obligation.
										</Text>
									</Box>

									<Box>
										<Text mb={4}>
											<Text as='strong'>5. Enterprise Services.</Text>
										</Text>
										<Text mb={6}>
											Valence Intelligence offers an enterprise package (the "Enterprise Service") to eligible
											customers. Through the Enterprise Service, Valence Intelligence will identify the Retailers' error
											in (1) handling your inventory; (2) accounting for sales transactions reported through the
											Retailers' portal(s) or other method; and (3) processing Retailers' product returns. Valence
											Intelligence then secures cash reimbursements and recovered inventory (the "Reimbursements") for
											these Retailer errors. You may subscribe to the Enterprise Service if you are currently subscribed
											to a Subscription (as defined below). If you subscribe to the Enterprise Service, in addition to
											your fee for the Subscription, Valence Intelligence will charge you a fee (the "Enterprise Service
											Fee") for the Reimbursements that Valence Intelligence secures you from Amazon or such other
											percentage as is indicated in your account settings in the Valence Intelligence Customer Portal
											(the "Service Rate"). In order to use the Enterprise Service, you must provide access to your
											Retailer account(s) including (1) limited sign-in and permission-based access to the Amazon
											account; and (2) developer based access to your Retailer account's reporting features through API
											where available. You must maintain Valence Intelligence's access to your Retailer account(s)
											during the term of your subscription to the Enterprise Service and for 120 days following the
											deactivation of the Enterprise Service. If you are eligible to subscribe to the Enterprise
											Service, you may do so at any time and your obligation to pay the Enterprise Service Fee begins as
											soon as you receive any Reimbursement.
										</Text>

										<Text mb={4}>In using providing the Enterprise Service, Valence Intelligence will:</Text>
										<UnorderedList spacing={3} pl={5} mb={6}>
											<ListItem>
												Limit use of its access to your Retailer account to providing the Enterprise Service and also
												restrict this access to the Valence Intelligence employees specifically authorized to provide
												the Enterprise Service. No third party, such as a contractor, will be used in providing the
												Enterprise Service.
											</ListItem>
											<ListItem>
												Copy certain data in your Retailer account ("Enterprise Service Customer Data") to Valence
												Intelligence's technical platform where the Enterprise Service Customer Data will be stored,
												formatted and compiled with other data.
											</ListItem>
											<ListItem>
												Display certain of the Enterprise Service Customer Data as well as information about Valence
												Intelligence's activities in performing the Enterprise Service on the Valence Intelligence
												Customer Portal website ("Customer Portal").
											</ListItem>
											<ListItem>
												Undertake reasonable efforts to ensure the security of Valence Intelligence's access to your
												Retailer account, Enterprise Service Customer Data on the Valence Intelligence platform and your
												access to the Customer Portal.
											</ListItem>
											<ListItem>
												Protect and preserve the confidentiality of Enterprise Service Customer Data, including by not
												sharing such data with any party except as required by law or to perform the Enterprise Service.
											</ListItem>
										</UnorderedList>

										<Text mb={4}>To use the Enterprise Service, you will:</Text>
										<UnorderedList spacing={3} pl={5}>
											<ListItem>
												Provide truthful and accurate information and documentation when requested by Valence
												Intelligence.
											</ListItem>
											<ListItem>
												Make your best efforts to supply to Valence Intelligence, upon request, information and certain
												types of documentation, including about your shipments to Retailer fulfillment centers, the
												packaged product size and weight specifications for the products offered by the Retailers, and
												Retailer fulfillment center removal order shipment receiving records.
											</ListItem>
											<ListItem>
												Not engage in any activity that interferes with Valence Intelligence providing the Enterprise
												Service, including, without prior arrangement, addressing the Retailer cases that Valence
												Intelligence submits in connection with your Retailer Account.
											</ListItem>
											<ListItem>
												Not attempt to decipher, decompile, reverse engineer or otherwise discover the methodology of
												the business processes or source code of the software that make up the Enterprise Service,
												including the Customer Portal.
											</ListItem>
											<ListItem>
												Protect and preserve the confidentiality of the proprietary information that Valence
												Intelligence shares with you, which may or may not be designated as confidential, the scope of
												the Enterprise Service provided, the business processes and software features used in providing
												the Enterprise Service, and other information
											</ListItem>
										</UnorderedList>
									</Box>
								</VStack>
							</Box>

							<Box id='payment' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Payment
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										<strong>1. Billing.</strong> Valence Intelligence may offer the Service through a variety of billing
										methods, which include, but are not limited to, one-time subscriptions, monthly recurring
										subscriptions, and annual recurring subscriptions (each, a "Subscription"). Valence Intelligence
										will bill you on the date you subscribe to the Service ("Subscription Date"), and will continue to
										bill you in accordance with your selected Subscription until you cancel the Service. You must cancel
										your Subscription at least fifteen days prior to the next billing date to avoid a Subscription
										renewal. If you subscribe to the Enterprise Service, we will also bill you each month for the
										previous month's Enterprise Service Fee based on the Service Rate multiplied by the total
										Reimbursements for the applicable month. Fees will be billed to your Payment Method on the calendar
										day corresponding to the Subscription Date. Subscription fees are fully earned upon payment.
										Enterprise Service Fees are earned by Valence Intelligence on your receipt of the applicable
										Reimbursements. In some cases your payment date may change, for example if your Payment Method has
										not successfully settled or if you upgrade your plan outside of your current billing cycle.
									</Text>

									<Text>
										<strong>2. Payment Methods.</strong> To use the Service you must provide a valid Payment Method. You
										can update your Payment Method by going to the "Settings" page of our Website under "Account
										Billing". Following any update, you authorize us to continue to charge the applicable Payment
										Method. All fees are charged in advance of the month in which you will use the Service. You
										authorize us to charge any Payment Method associated to your account in case your primary Payment
										Method is declined or no longer available to us for payment of your subscription fee. You remain
										responsible for any uncollected amounts. You authorize us to charge your Payment Method for past due
										balances, including Enterprise Service Fees, when a new Payment Method is added and when the Account
										you have purchased changes status via an upgrade or downgrade.
									</Text>

									<Box>
										<Text mb={4}>
											If your account is past due for more than 7 days, we will automatically downgrade your account to
											the "free" version, which has limited functionality and does not provide access to many of our
											paid service tools. Should a downgrade occur, you may move your account to the service tier you
											desire by paying the designated monthly fees in advance.
										</Text>
										<Text>
											If your Enterprise Service Fee is past due for more than 30 days, we will charge a late fee equal
											to 1.5% per month or the highest amount permitted under applicable law. If a payment is not
											successfully settled, due to expiration, insufficient funds, or otherwise, and you do not cancel
											your account, we may suspend your access to the service until we have successfully charged a valid
											Payment Method. For some Payment Methods, the issuer may charge you certain fees relating to the
											processing of your Payment Method. Check with your Payment Method service provider for details.
											You will reimburse us for the costs of collection for any late payments including reasonable
											attorney's fees.
										</Text>
									</Box>

									<Text>
										<strong>3. Cancellation.</strong> You can cancel your membership at any time, and you will continue
										to have access to the Service through the end of your pre-paid billing cycle. We do not provide
										refunds or credits for any partial membership periods. Cancellation requests should be completed by
										emailing
										<strong> client_support@valenceintel.com </strong> with your organization name and account owner
										email. If you cancel your membership, your account will automatically close at the end of your
										current billing period.
									</Text>

									<Text>
										<strong>4. Changes to the Price and Subscription Plans.</strong> We reserve the right to change our
										subscription plans or adjust pricing for our Service or the Enterprise Service at any time as we may
										determine in our sole and absolute discretion. Except as otherwise expressly provided for in these
										Terms, any price changes or changes to your subscription plan will take effect following notice to
										you.
									</Text>

									<Text>
										<strong>5. No Refunds.</strong> Payments are nonrefundable, and there are no refunds or credits for
										partially used periods. Following any cancellation, however, you will continue to have access to the
										service through the end of your current billing period.
									</Text>
								</VStack>
							</Box>

							<Box id='disclaimers' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Disclaimers of Warranties and Limitations on Liability
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										<strong>1. Disclaimer of Warranties.</strong> THE MATERIALS ON THIS WEBSITE ARE PROVIDED ON AN 'AS
										IS' BASIS. VALENCE INTELLIGENCE MAKES NO WARRANTIES, EXPRESSED OR IMPLIED, AND HEREBY DISCLAIMS ALL
										OTHER WARRANTIES, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF
										MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY OR
										OTHER VIOLATION OF RIGHTS. FURTHER, VALENCE INTELLIGENCE DOES NOT WARRANT OR MAKE ANY
										REPRESENTATIONS THAT THE CONTENT OR SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE AND MAKES NO
										WARRANTIES CONCERNING THE ACCURACY, COMPLETENESS, LIKELY RESULTS, OR RELIABILITY OF THE USE OF THE
										MATERIALS ON ITS WEBSITE OR OTHERWISE RELATING TO SUCH MATERIALS OR ON ANY SITES LINKED TO THIS
										SITE.
									</Text>
									<Text>
										YOU ARE RESPONSIBLE FOR BACKING UP YOUR DATA, AND VALENCE INTELLIGENCE SHALL HAVE NO LIABILITY FOR
										ANY LOSS OF DATA. WE ARE NOT RESPONSIBLE FOR ANY CHANGES IN YOUR AMAZON ACCOUNT STATUS OR SERVICES
										BASED ON ANY ACTION TAKEN BY YOU, SUCH AS OPENING AN EXCESSIVE AMOUNT OF AMAZON SUPPORT CASES IN A
										SINGLE DAY OR OPENING CASES THAT ARE NOT VALID. VALENCE INTELLIGENCE DOES NOT GUARANTEE THAT ANY
										DISCREPANCY FOUND BY THE SERVICE IS 100% ACCURATE. YOU ACKNOWLEDGE THAT ERRORS IN RESULTS CAN BE
										DERIVED FROM A VARIETY OF SOURCES, INCLUDING AMAZON ACCESS LIMITATIONS AND LIMITATIONS IN AMAZON'S
										INTERNAL TOOLS. YOU ARE RESPONSIBLE FOR VALIDATING ALL DISCREPANCIES UNCOVERED BY THE SERVICE BEFORE
										OPENING A CASE WITH AMAZON AND FOR LIMITING THE NUMBER OF CASES YOU OPEN TO AVOID ANY NEGATIVE
										EFFECTS ON YOUR AMAZON ACCOUNT STANDING.
									</Text>

									<Text>
										<strong>2. Limitation of Liability.</strong> EXCEPT TO THE MINIMUM EXTENT REQUIRED BY APPLICABLE LAW
										AND THEN ONLY TO THAT EXTENT, IN NO EVENT WILL VALENCE INTELLIGENCE, ITS EMPLOYEES, OR SUPPLIERS BE
										LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA OR PROFIT, OR DUE TO
										BUSINESS INTERRUPTION) ARISING OUT OF THE USE OR INABILITY TO USE THE MATERIALS ON VALENCE
										INTELLIGENCE'S WEBSITE, EVEN IF VALENCE INTELLIGENCE OR A VALENCE INTELLIGENCE AUTHORIZED
										REPRESENTATIVE HAS BEEN NOTIFIED ORALLY OR IN WRITING OF THE POSSIBILITY OF SUCH DAMAGE.
									</Text>
									<Text>
										BECAUSE SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR LIMITATIONS OF
										LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THESE LIMITATIONS MAY NOT APPLY TO YOU. OUR TOTAL
										AGGREGATE LIABILITY FOR ALL CLAIMS MADE ABOUT THE SERVICE WILL BE NO MORE THAN WHAT YOU PAID US IN
										THE SIX MONTHS PRIOR TO THE EVENT GIVING RISE TO THE APPLICABLE CLAIM.
									</Text>

									<Text>
										<strong>3. Results Not Guaranteed.</strong> Valence Intelligence makes no guarantee or
										representation of any kind concerning the results of your use of the Website or Service. Any
										testimonials or examples displayed or depicted through Valence Intelligence's Website, programs,
										and/or the Service are only examples of what may be possible.
									</Text>

									<Text>
										<strong>4. Indemnification for Breach of Terms of Use.</strong> You agree to indemnify and hold
										Valence Intelligence, its officers, directors, shareholders, predecessors, successors in interest,
										employees, agents, subsidiaries, and affiliates harmless from any demands, loss, liability, claims,
										or expenses (including attorneys' fees), made against Valence Intelligence by any third party due to
										or arising out of or in connection with your use of the Service or Website.
									</Text>

									<Text>
										<strong>5. Third-Party Resources.</strong> The Website and the Service may contain links to
										third-party websites or other resources. You acknowledge and agree that we are not responsible or
										liable for the availability, accuracy, content, or policies of third-party websites or other
										resources. Links to such websites or resources are provided only as a convenience to you.
									</Text>
								</VStack>
							</Box>

							<Box id='security' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Security, Storage, & Retention
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										<strong>1. Security.</strong> The privacy and protection of your data is of the utmost importance to
										us. We take all reasonable technical and organizational precautions to protect the confidentiality,
										security, and integrity of your Personal Data. Although we use multiple and various security
										measures to help protect your Personal Data against loss, misuse, or unauthorized disclosure, we
										cannot 100% guarantee the security of information transmitted to us over the Internet.
									</Text>

									<Text>
										<strong>2. Storage.</strong> The Personal Data that you provide to us is generally stored on servers
										located in the United States. If you are located in another jurisdiction, you should be aware that
										once your Personal Data is submitted through our Service, it will be transferred to our servers in
										the United States and that the United States currently does not have uniform data protection laws in
										place.
									</Text>

									<Text>
										<strong>3. Retention.</strong> We will retain your information for as long as needed to provide you
										with our service. If you wish to cancel your account or request that we no longer use your
										information to provide you service, contact us at <strong>client_support@valenceintel.com</strong>.
										We will retain only that information necessary to comply with our legal obligations, resolve
										disputes, and enforce our agreements.
									</Text>
								</VStack>
							</Box>

							<Box id='miscellaneous' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Miscellaneous
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										<strong>1. Privacy Policy.</strong> Valence Intelligence may use and disclose your information
										according to our{' '}
										<Link href='https://app.valenceintel.com/#/privacy_policy' isExternal color='blue.500'>
											Privacy Policy
										</Link>
										. Our Privacy Policy is incorporated into these Terms of Service.
									</Text>

									<Text>
										<strong>2. Intellectual Property.</strong> The Website, its original content, features,
										functionality (including look), our trademarks, service marks, logos, and Service content
										(“Intellectual Property”) are owned by Valence Intelligence and are protected by United States and
										international copyright, trademark, patent, trade secret, and other intellectual property or
										proprietary rights laws. You agree not to copy, modify, create derivative works of, publicly
										display, publicly perform, or republish any of our Intellectual Property.
									</Text>

									<Text>
										<strong>3. Customer Data.</strong> “Customer Data” means information, data, and other content, in
										any form or medium, including your billing and other personal information, that is submitted,
										posted, or otherwise transmitted by you, or a third party on your behalf, through the Service. As
										between you and Valence Intelligence, you own all right, title, and interest in the Customer Data.
										You grant Valence Intelligence a non-exclusive, royalty-free, worldwide license to use the Customer
										Data for service provision and analytics purposes.
									</Text>

									<Text>
										<strong>4. International Users.</strong> The Service is controlled, operated, and administered by
										Valence Intelligence from our offices within the USA and Canada. If you access the Service from a
										location outside the USA, you are responsible for compliance with all local laws.
									</Text>

									<Text>
										<strong>5. Compliance with Law.</strong> You represent and warrant that your use of Valence
										Intelligence’s Service complies with all applicable laws. If you are outside the United States, it
										is your responsibility to determine compliance with local laws. You agree to indemnify and hold us
										harmless from any losses, including attorney fees, resulting from a breach of this warranty.
									</Text>

									<Text>
										<strong>6. Force Majeure.</strong> We shall not be liable for any failure or delay in the
										performance of this agreement due to causes beyond our reasonable control, including but not limited
										to acts of God, war, hackers, third-party internet providers, government orders, power failures,
										pandemics, labor strikes, or any other force majeure event.
									</Text>

									<Text>
										<strong>7. Severability.</strong> If any provision of these Terms is found invalid or unenforceable,
										the remaining provisions shall continue in full force and effect.
									</Text>

									<Text>
										<strong>8. Survivability.</strong> Any provision imposing obligations after termination or
										expiration of this agreement shall survive termination or expiration.
									</Text>

									<Text>
										<strong>9. Headings.</strong> Section headings in these Terms are for reference purposes only and
										shall not affect interpretation.
									</Text>

									<Text>
										<strong>10. Waiver.</strong> Our failure or delay in exercising any right, power, or privilege under
										these Terms shall not operate as a waiver.
									</Text>

									<Text>
										<strong>11. Governing Law.</strong> This Agreement shall be governed by the laws of the State of
										Delaware, without regard to its conflict of law rules.
									</Text>

									<Text>
										<strong>12. Dispute Resolution.</strong> Any dispute arising under this agreement shall be settled
										exclusively by binding arbitration in Wilmington, Delaware, in accordance with the expedited
										Commercial rules of the American Arbitration Association. The arbitrator shall not have the power to
										award punitive or consequential damages. Arbitration shall proceed on an individual basis without
										class action claims.
									</Text>

									<Text>If you violate these Terms, we may seek injunctive or other equitable relief.</Text>
								</VStack>
							</Box>

							<Box id='terms' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Additional Terms
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										<strong>1. Assignment.</strong> You may not assign any of your rights under this agreement to anyone
										else. We may assign our rights at our discretion.
									</Text>

									<Text>
										<strong>2. Notice.</strong> All notices to you will be effective when we send them to the last email
										or physical address you provided or when posted on our Website. Any notice to us will be effective
										when delivered to:
									</Text>

									<Box bg='gray.100' p={4} borderRadius='md'>
										<Text fontWeight='bold'>
											client_support@valenceintel.com <br />
											Valence Intelligence <br />
											8 The Green, Suite A <br />
											Dover, DE 19701
										</Text>
									</Box>
								</VStack>
							</Box>

							<Box id='copyright' pt={4}>
								<Heading
									as='h2'
									size='lg'
									mb={4}
									pb={2}
									borderBottom='2px'
									borderColor={borderColor}
									color={headingColor}
								>
									Copyright Infringement
								</Heading>
								<VStack align='stretch' spacing={4}>
									<Text>
										If copyrighted content that belongs to you was posted on the Website without your permission, please
										notify us at:
									</Text>

									<Box bg='gray.100' p={4} borderRadius='md'>
										<Text fontWeight='bold'>
											client_support@valenceintel.com <br />
											Valence Intelligence <br />
											8 The Green, Suite A <br />
											Dover, DE 19701
										</Text>
									</Box>

									<Text fontWeight='bold'>Please include in your notice:</Text>
									<UnorderedList spacing={3} pl={5}>
										<ListItem>
											An electronic or physical signature of the copyright owner or someone authorized to act on their
											behalf.
										</ListItem>
										<ListItem>The name, address, telephone number, and email address of the copyright owner.</ListItem>
										<ListItem>Identification of the copyrighted work that is being infringed.</ListItem>
										<ListItem>
											Identification of where the infringing material is located on our Website (a URL works best).
										</ListItem>
										<ListItem>
											A statement that you have a good faith belief that the use isn’t authorized by the copyright
											owner, its agent, or the law.
										</ListItem>
										<ListItem>A statement that the information in your notice is accurate.</ListItem>
										<ListItem>A statement that you’re authorized to act on behalf of the copyright owner.</ListItem>
										<ListItem>
											This statement must be made under penalty of perjury, meaning if any part of the statement is
											false, you could be committing perjury—a serious offense that’s sometimes even classified as a{' '}
											<strong>felony.</strong>
										</ListItem>
									</UnorderedList>

									<Text fontWeight='bold'>Warning:</Text>
									<Text>
										If you knowingly make a false statement in your claim of copyright infringement, then you may be
										subject to liability for damages and heavy civil penalties. If you are not sure whether material on
										one of our Websites infringes your copyright, then you should speak with a lawyer before notifying
										us. We may forward your notice to the user that uploaded the content.
									</Text>

									<Text>
										<strong>1. Electronic Communications.</strong> When you use the Service, or send emails, text
										messages, and other communications from your desktop or mobile device to us, you are communicating
										with us electronically. You consent to receive communications from us. We will communicate with you
										in a variety of ways, such as by e-mail, text, or by posting notices and messages on this Website.
										You agree that all agreements, notices, disclosures, and other communications that we provide to you
										electronically satisfy any legal requirement that such communications be in writing.
									</Text>

									<Text>
										<strong>2. Entire Agreement.</strong> These terms, together with our Privacy Policy and any
										additional terms you have agreed to, constitute the entire agreement with respect to its subject
										matter and supersede all prior agreements, representations, and understandings of the parties,
										whether written or oral.
									</Text>
								</VStack>
							</Box>
						</VStack>
					</Card>
				</Box>
			</Flex>
		</Box>
	)
}

export default TermsAndConditions
